.program-section-title {
  font-size: 2.1rem;
  font-weight: 700;
  scroll-margin-top: 5rem;
  color: rgba(0,0,0,0.85);
}

.program-item {
  display: flex;
  z-index: 2;
}
.program-item__main {
  flex-grow: 1;
  padding-left: var(--p-content-main);
  padding-right: var(--p-content-main);
  margin-bottom: 2.5rem;
}
.program-item__content {
  line-height: 1.6rem;
}
.program-item__extra {
  width: var(--w-extra);
  max-width: 45%;
  flex-shrink: 0;
  padding: 0 3.5rem;
  z-index: 2;
  margin-bottom: 4rem;
}


.program-item__title {
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.1rem;
  scroll-margin-top: 2rem;
  color: rgba(0,0,0,0.9);
}
.highlight {
  background-color: rgba(152, 195, 121, 0.3);
  background-color: rgb(158, 199, 30, 0.2);
  border-radius: 1px;
  transition: background-color 0.5s ease; 
}

.program-task {
  margin-bottom: 1.2rem;
  line-height: 1.6rem;
}
.program-task__title {
  font-weight: 600;
  color: rgba(0,0,0,0.85);
}
.program-task__content {
  color: rgba(0,0,0,0.85);
  font-size: 0.99rem;
  margin: 0.5rem 0;
}
.program-task__more {
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  font-weight: 700;
}
.program-item__completed {
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  position: relative;
  top: 0.3rem;
  font-weight: 700;
}
.program-task__difficulty {
  opacity: 0.5;
  font-size: 0.93rem;
  margin-left: 0.3rem;
}

.program-task .collapse {
  margin-top: 0.1rem;
}
.collapse-similar {
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
}
.collapse-similar .collapse-content {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-left: 2px dashed rgba(75, 148, 203, 0.5);
  border-left: 2px solid rgba(75, 148, 203, 0.5);
  margin-left: 0.45rem;
}
.collapse-similar .collapse-content {
  padding-left: 1.5rem; 
}
.program-task .code-block {
  margin-left: 0 !important;
}


@media screen and (max-width: 1000px) {
  .program-item {
    display: block;
  }
  .program-item__extra {
    margin-left: 4rem;
    margin-bottom: 2rem;
    padding: 1rem 2rem 1rem 2rem;
    max-width: unset;
    width: unset;

    background-image: radial-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 0);
    background-size: 3px 3px;
  }
  .program-item__extra_empty {
    display: none;
  }
}